<template>
  <div>
    <div
      class="flex flex-row items-center px-6 pb-4 border-b">
      <span class="text-md">Buscar servicios</span>
      <div class="ml-auto">
        <sa-icon-button
          with-bg
          iconClass="text-xl bx bx-x"
          @click="cancel" />
      </div>
    </div>
    <div class="px-6 py-4">
      <el-input
        v-model="queryServices.textSearch"
        ref="textSearch">
        <i slot="prefix" class='mt-2 text-xl bx bx-search' />
      </el-input>
    </div>
    <div>
      <div
        v-if="filteredServices.length === 0"
        class="py-8 text-center">
        <div
          v-if="queryServices.textSearch"
          class="inline-block w-full">
          <i class="text-3xl bx bx-confused" />
          <div>
            <span>No se encontraron servicios que coincidan con</span>
            <span class="mx-1 italic font-bold">{{ textSearch }}</span>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="px-4 py-2">
          <services-search-list
            :services="filteredServices"
            @onChangeSelectedServices="onChangeSelectedServices"
          />
        </div>
      </div>
    </div>
    <div class="px-6 pt-6 border-t">
      <div
        v-if="errorMessage"
        class="py-2 text-right">
        <span class="text-red-500">{{ errorMessage }}</span>
      </div>
      <div class="flex flex-row justify-end space-x-2">
        <sa-button
          type="secondary"
          @click="cancel">
          Cancelar
        </sa-button>
        <sa-button
          type="primary"
          @click="selectServices">
          Seleccionar
        </sa-button>
      </div>
    </div>
  </div>
</template>
<script>
import _ from 'lodash';
import error from '@/mixins/error';
import CategoryService from '@/services/CategoryService';

export default {
  name: 'ServiceSearch',
  components: {
    ServicesSearchList: () => import('./ServicesSearchList.vue'),
  },
  props: {
    textSearch: {
      type: String,
      default: () => null,
    },
  },
  data() {
    return {
      errorMessage: null,
      filteredServices: [],
      selectedServices: [],
      queryServices: {
        textSearch: null,
        pageSize: 10,
        pageNumber: 1,
      },
    };
  },
  mounted() {
    this.$refs.textSearch.focus();
    this.queryServices.textSearch = this.textSearch;
  },
  watch: {
    'queryServices.textSearch': {
      deep: true,
      handler: _.debounce(async function () {
        await this.loadServices(this.queryServices)
      }, 300)
    },
  },
  methods: {
    async loadServices(queryServices) {
      try {
        const result = await CategoryService.Service.get({ textSearch: queryServices.textSearch });
        this.filteredServices = result;
      }
      catch {
        this.$message.error(this.getErrorMessage(error));
      }
    },
    cancel() {
      this.$emit('cancel');
    },
    selectServices() {
      if(this.selectedServices.length === 0) {
        this.errorMessage = 'Seleccione uno o más servicios para continuar';
        return;
      }
      this.$emit('onSelectServices', this.selectedServices);
    },
    onChangeSelectedServices(selectedServices) {
      this.selectedServices = selectedServices;
    }
  },
}
</script>
